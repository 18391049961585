var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ax-stacked-bar-chart",class:{
    'ax-stacked-bar-chart--no-title': !_vm.hasTitles,
    'ax-stacked-bar-chart--no-actions': !_vm.hasActions,
  }},[_c('div',{ref:"chartContent",staticClass:"ax-stacked-bar-chart__content",class:!!_vm.maxHeight && _vm.maxHeight !== '100%'
        ? 'ax-stacked-bar-chart__content--limited-height'
        : '',style:({ maxHeight: _vm.maxHeight, minHeight: _vm.minHeight }),on:{"mouseup":_vm.handleTitleResizeButtonMouseUp,"mousemove":_vm.handleTitleResizeButtonMouseMove,"mouseleave":_vm.handleTitleResizeButtonMouseLeave}},[(_vm.hasTitles)?_c('button',{staticClass:"ax-stacked-bar-chart__resize-title__wrapper",style:({
        left: ((_vm.finalTitleWidth - 10) + "px"),
        height: _vm.chartContentHeight || '100%',
      }),attrs:{"aria-hidden":"true","tabindex":"-1"},on:{"mousedown":_vm.handleTitleResizeButtonMouseDown}},[_c('div',{staticClass:"ax-stacked-bar-chart__resize-title__indicator"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiDragHorizontal))])],1),_c('div',{staticClass:"ax-stacked-bar-chart__resize-title__line"})]):_vm._e(),_c('div',{staticClass:"ax-stacked-bar-chart__content__bg",style:({
        left: _vm.hasTitles ? ((_vm.finalTitleWidth - 3) + "px") : '0px',
        width: _vm.backgroundAndNoResultsWidth,
        height: _vm.chartContentHeight || '100%',
      })},_vm._l((_vm.ticks),function(tick){return _c('div',{key:tick,staticClass:"ax-stacked-bar-chart__content__bg__tick",class:("ax-stacked-bar-chart__content__bg__tick--" + tick)})}),0),(!_vm.data.length)?_c('div',{staticClass:"ax-stacked-bar-chart__no-results",style:({
        left: _vm.hasTitles ? ((_vm.finalTitleWidth - 3) + "px") : '1rem',
        width: _vm.backgroundAndNoResultsWidth,
        height: _vm.chartContentHeight || '100%',
      })},[_vm._v(" "+_vm._s(_vm.translations.noResults || 'No Results')+" ")]):_vm._e(),_vm._l((_vm.data),function(value,key,index){return _c('ax-stacked-bar',{key:index,attrs:{"title":value.title,"max-value":value.maxValue || _vm.finalMaxValue,"display-config":value.displayConfig || _vm.legendConfig,"data":value.data,"data-links":value.dataLinks,"actions":value.actions,"metadata":value.metadata,"title-width":_vm.finalTitleWidth,"hidden-segments":_vm.hiddenSegmentsInternal,"hide-tooltip":_vm.hideTooltip},scopedSlots:_vm._u([(_vm.hasTitleSlot)?{key:"title",fn:function(ref){
      var titleSlotData = ref.titleSlotData;
return [_vm._t("title",null,{"titleSlotData":titleSlotData})]}}:null,(_vm.hasActionsSlot)?{key:"actions",fn:function(ref){
      var actionsSlotData = ref.actionsSlotData;
return [_vm._t("actions",null,{"actionsSlotData":actionsSlotData})]}}:null,(_vm.hasTooltipSlot)?{key:"tooltip",fn:function(ref){
      var tooltipSlotData = ref.tooltipSlotData;
return [_vm._t("tooltip",null,{"tooltipSlotData":tooltipSlotData})]}}:null],null,true)})})],2),_c('div',{staticClass:"ax-stacked-bar-chart__bottom-border"}),_c('div',{staticClass:"ax-stacked-bar-chart__x-axis",style:({
      marginLeft: ((_vm.hasTitles ? _vm.finalTitleWidth - 3 : 0) + "px"),
      marginRight: _vm.hasActions ? '4rem' : 0,
    })},_vm._l((_vm.ticks),function(tick,index){return _c('div',{key:tick,class:("ax-stacked-bar-chart__x-axis__tick ax-stacked-bar-chart__x-axis__tick--" + tick)},[_c('div',{staticClass:"ax-stacked-bar-chart__x-axis__tick__mark",class:index === 0 && !_vm.hasTitles
            ? 'ax-stacked-bar-chart__x-axis__tick__mark--regular-thickness'
            : ''}),_c('div',{staticClass:"ax-stacked-bar-chart__x-axis__tick__text"},[_vm._v(_vm._s(tick))])])}),0),_c('div',{staticClass:"ax-stacked-bar-chart__footer"},[_c('div',{staticClass:"ax-stacked-bar-chart__footer_legend"},[_c('ax-chart-legend',{attrs:{"legend-config":_vm.legendConfig,"hidden-segments":_vm.hiddenSegmentsInternal},on:{"toggle-legend-item":_vm.toggleHiddenSegments}})],1),_vm._t("footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }