import { render, staticRenderFns } from "./StackedBarChart.vue?vue&type=template&id=adfb6834&scoped=true&"
import script from "./StackedBarChart.vue?vue&type=script&lang=ts&"
export * from "./StackedBarChart.vue?vue&type=script&lang=ts&"
import style0 from "./StackedBarChart.vue?vue&type=style&index=0&id=adfb6834&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adfb6834",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
